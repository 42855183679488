<template>
  <b-card-body>
    <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
    />
  </b-card-body>
</template>

<script>
import apexChatData from '@/views/reports/apexChartData'
import VueApexCharts from 'vue-apexcharts'


export default {
  name: 'ClientsByDate',
  components: {
    VueApexCharts
  },
  props:['labels','values'],
  computed:{
    series() {
      return this.$props.values;
    },
    categories() {
      return {
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ['white'],
            colors: ['red', 'blue'],
          },
          colors: ['red', 'blue'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: this.$props.labels,
          },
          yaxis: {},
        }
      }
    }
  },
  data() {
    return {
      lineChartSimple: {
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ['white'],
            colors: ['red', 'blue'],
          },
          colors: ['red', 'blue'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {},
        }
      },
    }
  },
  watch:{
    values: {
      immediate: false,
      handler(val) {
        this.lineChartSimple.series = val.map(item => Object.assign({},{data: item}))
      }
    },
    labels: {
      immediate: false,
      handler(val){
        this.lineChartSimple.chartOptions = {...this.lineChartSimple.chartOptions,...{xaxis:{categories:val}}};
      }
    }
  }

}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./ClientsByDate.vue?vue&type=template&id=141313de&scoped=true&"
import script from "./ClientsByDate.vue?vue&type=script&lang=js&"
export * from "./ClientsByDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141313de",
  null
  
)

export default component.exports